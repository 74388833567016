// const baseurl = "https://tst.orbithc.net/aicapturewrapper/api/v1"
const baseurl = "https://ohc-qav4-webcireai-301.azurewebsites.net/v1";
// const baseurl = "https://localhost:7293/v1";
// const baseurl = "https://tst.orbithc.net/coreaiapi/v1";
// const baseurl = "https://ohc-ops4-webcoreai-701.azurewebsites.net/v1";

module.exports = {
  baseurl,
  LoginAPI: "/LoginService/SignIn",
  locationByServiceTypesUrl: "/LoginService/locationByServiceTypes",
  PatientList: "/PatientService/SearchPatient",
  // SendSMSAPI: "/PatientService/InsertPatientSmartSearchAIList",
  SendSMSAPI: "/CaptureService/CapturePatient",
  ProcessImport: "/Insurance/ProcessImportFields",
  GetModules: "/Insurance/GetImportFieldSettings",
  PatientDetails: "/PatientService/GetPatientData",
  GetBatchNumbers: "/Insurance/GetReportFieldsAndData",
  GetReportsList: "/Insurance/GetDataByModule",
  InsurenseDetails: "/CaptureService/VerifyInsurance",
  Captur: "/CaptureService/CaptureInsurance",
  DiscoverInsurance: "/CaptureService/InsuranceDiscovery",
  QrCodeUrl: "/CaptureService/GetOrgLocGuids",
  IdentifyPayerUrl: "/CaptureService/PayerIdentifierRealtime",
  CaptureUpload: "/CaptureService/CallPreSign",
  CorePresign: "/CaptureService/CallCorePreSign",
  Edi837JSONData: "/Edi837JSONDataService/Edi837JSONData",
  saveEdi837ClaimUrl: "/Edi837JSONDataService/InsertEDI837JSONData",
  getDocumentSummaryAI: "/Edi837JSONDataService/GetDocumentSummaryAI",
  getDocumentSummaryAIList: "/Edi837JSONDataService/GetDocumentSummaryList",
  getPayerNamesList: "/Edi837JSONDataService/GetPayerNamesList",
  EdiClaimStatusUrl: "/Edi837JSONDataService/ChClaimStatus",
  GetDocumentList: "/Insurance/GetDocVerifyList",
  GetLookUpsForDV: "/Insurance/GetDocumentLookups",
  RecognizeDocument: "/Insurance/RecognizeDocument",
  DocumentValidation: "/Insurance/PayerIdentifier",
  DocumentVerification: "/Insurance/InsuranceVerification",
  CallBatchPreSign: "/CaptureService/CallBatchPreSign",
  UploadBatchFiles: "/Insurance/UploadBatchFiles",
  GetDocumentById: "/Insurance/GetDocumentById",
  GetBlobFileStream: "/Insurance/DocumentPrompt",
  GetBlobFile: "/Insurance/GetBlobFileStream",
  GetExportElementsData: "/Insurance/GetExportElementsData",
  GetICDs: "/Claim/GetICDs",
  GetLookUpsForDS: "Insurance/GetDocSummaryLookups",
  GetDocSummaryData: "Insurance/GetDocSummaryData",
  GetServicesCPT: "/Claim/GetProcedures",
  GetServicesModifier: "/Claim/GetModifiers",
  GetLocationList: "/GetLocationList", //change this to original one proper end point
  GetTaskSummaryData: "Insurance/GetTaskSummaryData",
  GetConfigLookup: "Insurance/GetConfigLookup",
  GetSessionValue: "/Common/UpdUserSession",
};
