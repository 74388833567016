export const sessionOutPayload = () => {
    const details = JSON.parse(sessionStorage?.getItem("details"));
  
    return {
      commonParams: {
        organizationID: details?.orgId,
        companyID: details?.companyId,
        coCode: details?.companyCode,
        groupID: details?.groupID,
        timeZone: details?.timeZone,
        sessionId: details?.sesionId,
        loginUserId: `${details?.userId}`,
        loginUserName: details?.userName,
        auditTrail: 0,
        editTrail: 0,
      },
      configId: "91234",
      ModuleId: "0",
      SectionId: "0",
      RecordId: "-1",
      RequestType: "",
      RequestString: "",
      UserId: details?.userId,
    };
  };
  