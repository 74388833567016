import React, { useState } from "react";
import "ag-grid-enterprise";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import SessionOut from "./sessionOut.js";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import * as serviceWorker from "./serviceWorker.js";
import { Snackbar, Button } from "@mui/material"; // Import MUI Snackbar and Button components

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

const RootComponent = () => {
  const [open, setOpen] = useState(false); // Snackbar open state
  const [registration, setRegistration] = useState(null); // To hold the registration object

  // Handle snackbar close
  const handleClose = () => {
    setOpen(false);
  };

  // Function to refresh the app (force update the service worker)
  const handleUpdate = () => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload(); // Reload the page to activate the new service worker
    }
  };

  // Register the service worker with update handling
  serviceWorker.register({
    onUpdate: (reg) => {
      setRegistration(reg); // Store the registration object
      setOpen(true); // Show the snackbar
    },
    onSuccess: (reg) => {
      console.log(
        "Service Worker registered successfully with scope:",
        reg.scope
      );
    },
  });

  return (
    <ErrorBoundary
      fallback={
        <div>
          <h1>Data loading failed. Please contact support team.!!</h1>
        </div>
      }
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <App />
            {/* <SessionOut /> */}
            <Snackbar
              open={open}
              onClose={handleClose}
              message="New version available!"
              action={
                <Button color="secondary" size="small" onClick={handleUpdate}>
                  REFRESH
                </Button>
              }
            />
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

// Render the root component
root.render(
  // <React.StrictMode>
  <RootComponent />
  // </React.StrictMode>
);

reportWebVitals();
