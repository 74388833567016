import { fetchGeoData } from '../../apis/login';

export async function handleLoginSubmit(mutateAsync, companyCode, userName, password, loginFrom = 'L', forceSession = "N") {
    debugger;
    let trackingInfo = "";
    let params = {};

    if (loginFrom !== 'S') {
        const trackingResponse = await fetchGeoData();
        if (trackingResponse && trackingResponse.status === 200) {
            const url = window.location.href;
            const { ip, city, region, country, hostname, loc } = trackingResponse.data;
            trackingInfo = `${url} | Country: (" ${country} ") | City: (" ${city} ") | Region: (" ${region} ") | IP: (" ${ip} ") | HostName: (" ${hostname} ") | LNL: ${loc}`;
        }
    }

    if (loginFrom === 'S') {
        params = {
            companyCode: companyCode,
            password: password,
            userName: userName,
            loginFrom: loginFrom.toLocaleUpperCase()
        };
    }
    else {
        params = {
            authType: "",
            companyCode: companyCode,
            password: password,
            userName: userName,
            forceSession: forceSession,
            trackingInfo: trackingInfo,
            loginFrom: "L"
        };
    }

    // LoginFrom: L means Login Screen. If it is S then, Session Timeout
    await mutateAsync(params);
}